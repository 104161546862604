<template>
    <div class=" padding-sides-md row flex-row flex-nowrap hide-scrollbar no-margin" style="overflow-y: scroll;scrollbar-width: none;-ms-overflow-style: none;background-color: #d4d4d43d;">
      <div
          class="col-auto"
          v-for="s in markets"
          v-bind:key="getKey(s.market_id)"
          v-bind:class="getMarketClass(s.market_id)"
          @click="setMarket(s)">
        <span v-text="s.market_name"></span>
      </div>

    </div>
</template>

<script>

export default {
  name: "Markets",
  methods: {
    getMarket: function(sport_id) {

      this.$store.dispatch("getMarkets",sport_id);
    },
    getKey: function(match_id) {

      var prefix = "popular-games-"+match_id;
      return Math.random().toString(10).replace('0.', 'odd-id-'+prefix + '-');

    },
    setMarket: function(market){

      this.market = market;
      this.market_id = market.market_id;
      this.market_name = market.market_name;
      this.$store.dispatch("setOutcomes",market.outcomes);
      this.$store.dispatch("setMarketID",market.market_id);
    },
    getMarketClass: function (marketID) {

      return parseInt(marketID) === parseInt(this.market_id) ? 'market-list-active' : 'market-list';

    }
  },
  computed: {
    markets: function() {
      return this.$store.state.markets
    }
  },
  components: {
  },
  watch: {
    sport_id: function (newValue, oldValue) {

      if(parseInt(newValue) === parseInt(oldValue)) {

        return;

      }

      this.getPopularGames();

    },
  },
  data: function() {
    return {
      market: {},
      competition_name: 'Top League',
      sport_name: 'Sports',
      market_name: 'Market',
      market_id: 1,

    }
  },
  mounted: function () {

    this.getMarket(1);

  }
  }
</script>